<script setup lang="ts">
const router = useRouter()

const muted = 'bg-muted-50 dark:bg-muted-900'
const white = 'bg-white dark:bg-muted-900'
const backgroundColor = ref(muted)

const setBackgroundColor = (route: any) => {
  setTimeout(() => {
    backgroundColor.value = route.meta.whiteBackground ? white : muted
  }, 500)
}

router.afterEach(setBackgroundColor)

setBackgroundColor(router.currentRoute.value)

// This is not really nice, but since this layout is full widht, we need to tell
// the content container(s) to add px-4 padding. For the public layout, this is
// applied in the layout itself.
provide('addContentContainerPadding', true)
</script>

<template>
  
  <div
    :class="backgroundColor"
    class="absolute flex min-h-screen w-full flex-col overflow-x-hidden"
  >
    <PublicHeader />
    <PublicCmsSidebarMenu />

    <div class="px-4">
      <slot />
    </div>

    <PublicFooter />
    <TairoPanels />
  </div>
</template>
